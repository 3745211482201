var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}}),_c('b-col',{attrs:{"md":"8"}},[_c('b-form',{staticClass:"chat-app-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"schoolsForm",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticClass:"pt-3"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"School Name *","label-for":"name"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{staticClass:"custom-field",attrs:{"autocomplete":"off","placeholder":"Enter School Name","id":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"number of seats","vid":"seats","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Seats Number *","label-for":"seats"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{staticClass:"custom-field",attrs:{"autocomplete":"off","placeholder":"Enter Number of Seats","type":"number","id":"seats"},model:{value:(_vm.form.total_seats),callback:function ($$v) {_vm.$set(_vm.form, "total_seats", $$v)},expression:"form.total_seats"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"teacher code","vid":"teachers_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Teacher Code *","label-for":"teachers_code"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{staticClass:"custom-field",attrs:{"autocomplete":"off","placeholder":"Enter Teacher Code","id":"teachers_code"},model:{value:(_vm.form.teachers_code),callback:function ($$v) {_vm.$set(_vm.form, "teachers_code", $$v)},expression:"form.teachers_code"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"student code","vid":"students_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Student Code *","label-for":"students_code"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{staticClass:"custom-field",attrs:{"autocomplete":"off","placeholder":"Enter Student Code","id":"students_code"},model:{value:(_vm.form.students_code),callback:function ($$v) {_vm.$set(_vm.form, "students_code", $$v)},expression:"form.students_code"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Expiry Date","vid":"expiry_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Expiry date *","label-for":"expiry_date"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('flat-pickr',{staticClass:"form-control custom-field",attrs:{"id":"expiry_date","placeholder":"Expiry Date (DD-MM-YYYY)","config":{ dateFormat: 'd/m/Y'}},model:{value:(_vm.form.expiry_date),callback:function ($$v) {_vm.$set(_vm.form, "expiry_date", $$v)},expression:"form.expiry_date"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)]}}])}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('b-button',{staticClass:"rounded-pill mt-2 mr-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"rounded-pill mt-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1),_c('b-col',{attrs:{"md":"2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }